<template>
  <div class="patentInfo" :style="{ height: infoHei }">
    <el-scrollbar>
      <div class="cont">
        <div class="cont-main col999">
          <div class="updateBox flex-between">
            <div class="type">
              <el-alert
                title="预开医嘱：设定医嘱的特定使用日期，多为一次性医嘱，在透析当日系统自动拉取"
                type="warning"
                show-icon
                :closable="false"
              >
              </el-alert>
            </div>
            <div class="upButtom">
              <el-button
                class="color-vice"
                type="primary"
                @click="openWeight('addLongIerm', '1')"
              >
                <i class="fa fa-plus-circle"></i>新增
              </el-button>
            </div>
          </div>
          <div class="tables">
            <el-table
              v-loading="tableLoading"
              :data="tableData"
              border
              size="small"
              max-height="400"
            >
              <el-table-column
                width="120"
                fixed
                prop="dict_advice_type_name"
                label="类别"
              />
              <el-table-column prop="advice_content" label="医嘱内容" />
              <el-table-column width="120" label="循环周期">
                一次性
              </el-table-column>
              <el-table-column
                width="90"
                prop="drug_type_name"
                label="透中用药"
              />
              <el-table-column
                width="90"
                prop="self_provided_name"
                label="个人库存"
              />
              <el-table-column width="160" prop="use_time" label="使用时间" />
              <el-table-column width="90" prop="use_flag_name" label="状态" />
              <el-table-column width="90" prop="doctor.name" label="开嘱医生" />
              <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="modParient(scope.row)"
                  >
                    <i class="txIons modify"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="delParient(scope.row)"
                  >
                    <i class="txIons del"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-scrollbar>

    <addLongIerm
      v-if="isAddLongIerm"
      ref="addLongIermRef"
      :patientId="patientId"
      @refreshDataList="getDataList"
    ></addLongIerm>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import service from '@/utils/request'
import { getConstHei } from '@/utils/tool.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import addLongIerm from './addLongIerm.vue'
export default {
  name: 'programme',
  components: {
    addLongIerm,
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      ruleForm: {},
      infoHei: '',
      searchData: {
        is_preopen: '1',
      },
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      tableData: [],
      isBatchLongIerm: false,
      batchLongIermRef: null,
      isAddLongIerm: false,
      addLongIermRef: null,
      tableLoading: false,
      patientId: route.params.id,
    })

    onMounted(() => {
      state.infoHei = getConstHei()
      getDataList()
    })
    const getDataList = async () => {
      state.searchData.patient_id = route.params.id
      state.searchData.current = state.pageData.currentPage
      state.searchData.size = state.pageData.pageSize
      state.tableLoading = true
      let res = await service.post('/api/advice/list', state.searchData)
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data.records
        state.pageData.totalSum = res.data.total
      }
    }

    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getDataList()
    }
    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getDataList()
    }

    const modParient = (item) => {
      state.isAddLongIerm = true
      nextTick(() => {
        state.addLongIermRef.initData(item, '1')
      })
    }
    const delParient = (item) => {
      ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('/api/advice/delete', {
            id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        })
        .catch(() => {})
    }

    const openWeight = (type, val) => {
      switch (type) {
        case 'batchLongIerm':
          state.isBatchLongIerm = true
          nextTick(() => {
            state.batchLongIermRef.initData()
          })
          break
        case 'addLongIerm':
          state.isAddLongIerm = true
          nextTick(() => {
            state.addLongIermRef.initData('', val)
          })
          break
      }
    }

    return {
      ...toRefs(state),
      openWeight,
      getDataList,
      handleSizeChange,
      handleCurrentChange,
      modParient,
      delParient,
    }
  },
}
</script>

<style scoped lang="scss">
.patentInfo {
  margin-top: 10px;
}
.podBot {
  padding-bottom: 30px;
}
.patInfo {
  width: 220px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 20px;
  margin-right: 32px;
}
.cont-main {
  flex: 1;
}
.updateBox {
  height: 37px;
  margin-bottom: 24px;
  .el-radio-group {
    margin-right: 30px;
  }
  .el-alert {
    // height: 32px;
    // line-height: 32px;
    background-color: rgba(49, 102, 174, 0.1);
    color: #3166ae;
    &:deep(.el-alert__title) {
      // font-size: 14px;
    }
    &:deep(.el-alert__icon) {
      // font-size: 14px;
      width: 14px;
    }
  }
}
.tables {
  margin-bottom: 40px;
}
.parient-tag {
  .el-tag {
    margin-right: 10px;
  }
}
</style>
